import React, { Component } from "react";
import moment from "moment-timezone";

import Tabs from "../../components/Tabs";
import SubHeader from "../../components/SubHeader";
import Loader from "../../components/Loader";
import Notifications from "../../components/Notifications";
import LocalizedStrings from "../../components/Localization";

import Pickers from "./Pickers";
import Stats from "./Stats";
import ChartPL from "./ChartPL";
import PeakPower from "./PeakPower";
import ChartMetervalues from "./ChartMetervalues";
import ChartPlatformPrice from "./ChartPlatformPrice";
import NettingChart from "./ChartNetting";
import Certificates from "./Certificates";
// import Flexibility from './Flexibility';

import config from "../../api/config";

import {
  getMetervaluesInjectionHourly,
  getMetervaluesOfftakeHourly,
  getMetervaluesProductionHourly,
} from "../../api/metervalues";
import { CtaButton } from "../../components/CallToAction";

moment.tz.setDefault("Europe/Brussels");

class Dashboards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sdps: {
        offtake: undefined,
        allOfftake: undefined,
        injection: undefined,
        allInjection: undefined,
        production: undefined,
        allProduction: undefined,
      },
      metervalues: {
        offtake: undefined,
        injection: undefined,
        production: undefined,
      },
      maxOfftakeMV: undefined,
      maxInjectionMV: undefined,
      maxProductionMV: undefined,
      offtakeSelectedCount: undefined,
      injectionSelectedCount: undefined,
      productionSelectedCount: undefined,
      belpex: [],
      minDate: undefined,
      minContractDate: undefined,
      notStarted: undefined,
      from: undefined,
      to: undefined,
      aggregation: undefined,
      data: null,
      error: undefined,
      selectedSdps: [],
    };

    this.renderOfftake = this.renderOfftake.bind(this);
    this.onSwitchSelectedEanOfftake =
      this.onSwitchSelectedEanOfftake.bind(this);

    this.renderInjection = this.renderInjection.bind(this);
    this.onSwitchSelectedEanInjection =
      this.onSwitchSelectedEanInjection.bind(this);

    this.renderProduction = this.renderProduction.bind(this);
    this.onSwitchSelectedEanProduction =
      this.onSwitchSelectedEanProduction.bind(this);

    this.renderCertificates = this.renderCertificates.bind(this);
    this.renderNetting = this.renderNetting.bind(this);

    this.onChangePeriod = this.onChangePeriod.bind(this);

    this.chartAggregationInfo = this.chartAggregationInfo.bind(this);
    this.handleSelectedSdpsChange = this.handleSelectedSdpsChange.bind(this); // Add the handler function

    this.strings = LocalizedStrings(
      {
        en: {
          loading: "Loading data...",
          desc: "The dashboard displays your energy consumption or injection for every hour, as well as the wholesale price. These amounts determine the energy component of your invoice or self-bill.",
          noMeasured:
            "Meterdata is not yet available for this period. In most cases, the meterdata arrives two days after delivery.",
          noEanSelected:
            "No EAN(s) selected, please select at least one to preview meterdata.",
          supply: "Supply",
          injection: "Injection",
          production: "Production",
          certificates: "Certificates",
          flexibility: "Flexibility",
          chartAggregationInfo1: "The chart displays",
          chartAggregationInfo2: "Due to large data set the chart displays",
          chartAggregationInfo3: "values for the selected EAN(s).",
          chartAggregationInfoHour: "hourly",
          chartAggregationInfoDay: "daily average",
          chartAggregationInfoMonth: "monthly average",
          startDate:
            "Red dashed line shows the contract start date. If multiple EANs are selected it shows the earliest contract start date.",
          endedContract: "of the selected EAN(s) have ended contract.",
          mvChangesInfo:
            "Due to changes in our IT system, measurement data is not available at this moment",
          hasFlexibility1: "Consult Yuso Inside",
          hasFlexibility2: "for more details of the following EAN(s):",
          cta4: "Add",
          cta5: "SDP",
        },
        nl: {
          loading: "Data laden...",
          desc: "Het dashboard geeft jouw energieverbruik of injectie voor elk uur weer, evenals de groothandelsprijs. Deze bedragen bepalen de energiecomponent van jouw factuur of self-bill.",
          noMeasured:
            "Meterdata is nog niet beschikbaar voor deze periode. Meestal ontvangen we de meetgegevens twee dagen na levering.",
          noEanSelected:
            "Geen EAN(s) geselecteerd, selecteer minimaal één om een voorbeeld van de meterdata te bekijken.",
          supply: "Levering",
          injection: "Injectie",
          production: "Productie",
          certificates: "Certificaten",
          flexibility: "Flexibiliteit",
          chartAggregationInfo1: "In het diagram worden",
          chartAggregationInfo2: "Vanwege de grote gegevensset worden",
          chartAggregationInfo3:
            "waarden weergegeven voor de geselecteerde EAN(s).",
          chartAggregationInfoHour: "uur",
          chartAggregationInfoDay: "dagelijks gemiddelde",
          chartAggregationInfoMonth: "maandelijks gemiddelde",
          startDate:
            "Rode stippellijn geeft de startdatum van het contract aan. Als er meerdere EANs zijn geselecteerd, wordt de vroegste startdatum van het contract weergegeven.",
          endedContract: "van de geselecteerde EAN(s) zijn beëindigd.",
          mvChangesInfo:
            "Door aanpassingen in ons IT systeem, zijn meetwaarden enkele dagen niet beschikbaar",
          hasFlexibility1: "Raadplegen Yuso Inside",
          hasFlexibility2: "voor meer details van de volgende EAN(s):",
          cta4: "Toevoegen",
          cta5: "Aansluitingspunt"
        },
      },
      this.props.lang
    );
  }

  handleSelectedSdpsChange(selectedSdps) {
    this.setState({ selectedSdps });
  }

  updateMetervalues(firstTime) {
    if (!this.props.selectedCompany) return;

    if (!(this.state.from && this.state.to)) return;

    if (firstTime) return;

    // get the selected tab
    let tab = window.location.hash.substring(1) || "supply"; // default

    const fixData = (data) => {
      const new_data = [...data];

      // charts needs at least 2 data points
      if (Array.isArray(new_data) && new_data.length === 1)
        new_data.push(new_data[0]);

      return new_data;
    };

    if (tab === "supply") {
      if (this.state.sdps.offtake.length > 0) {
        getMetervaluesOfftakeHourly(
          this.props.selectedCompany.company_id,
          this.state.sdps.offtake
            ? this.state.sdps.offtake.map((x) => x.ean)
            : null,
          this.state.from,
          this.state.to,
          false, // grouped
          false, // hourly
          (err, res) => {
            if (err) return window.reportError.send(err);

            this.setState({
              metervalues: {
                offtake: fixData(res.mv),
                injection: this.state.metervalues.injection,
                production: this.state.metervalues.production,
              },
              aggregation: res.aggregation,
              maxOfftakeMV:
                res.mv &&
                res.mv.reduce((a, b) => {
                  return a.max_mv > b.max_mv ? a : b;
                }, {}),
              belpex: fixData(res.belpex),
              minDate: res.minDate,
              minContractDate: res.minContractDate,
              notStarted: res.notStarted,
              offtakeSelectedCount: this.state.sdps.offtake
                ? this.state.sdps.offtake.map((x) => x.ean).length
                : 0,
            });
          }
        );
      }
    } else if (tab === "injection") {
      if (this.state.sdps.injection.length > 0) {
        getMetervaluesInjectionHourly(
          this.props.selectedCompany.company_id,
          this.state.sdps.injection
            ? this.state.sdps.injection.map((x) => x.ean)
            : null,
          this.state.from,
          this.state.to,
          false, // grouped
          false, // hourly
          (err, res) => {
            if (err) return window.reportError.send(err);

            this.setState({
              metervalues: {
                offtake: this.state.metervalues.offtake,
                injection: fixData(res.mv),
                production: this.state.metervalues.production,
              },
              aggregation: res.aggregation,
              maxInjectionMV:
                res.mv &&
                res.mv.reduce((a, b) => {
                  return a.max_mv > b.max_mv ? a : b;
                }, {}),
              belpex: fixData(res.belpex),
              minDate: res.minDate,
              minContractDate: res.minContractDate,
              notStarted: res.notStarted,
              injectionSelectedCount: this.state.sdps.injection
                ? this.state.sdps.injection.map((x) => x.ean).length
                : 0,
            });
          }
        );
      }
    } else if (tab === "production") {
      if (this.state.sdps.production.length > 0) {
        getMetervaluesProductionHourly(
          this.props.selectedCompany.company_id,
          this.state.sdps.production
            ? this.state.sdps.production.map((x) => x.ean)
            : null,
          this.state.from,
          this.state.to,
          false, // grouped
          false, // hourly
          (err, res) => {
            if (err) return window.reportError.send(err);

            this.setState({
              metervalues: {
                offtake: this.state.metervalues.offtake,
                injection: this.state.metervalues.injection,
                production: fixData(res.mv),
              },
              aggregation: res.aggregation,
              maxProductionMV:
                res.mv &&
                res.mv.reduce((a, b) => {
                  return a.max_mv > b.max_mv ? a : b;
                }, {}),
              productionSelectedCount: this.state.sdps.production
                ? this.state.sdps.production.map((x) => x.ean).length
                : 0,
            });
          }
        );
      }
    }
  }

  onChangePeriod(from, to, firstTime) {
    this.setState(
      {
        from,
        to,
        metervalues: {
          offtake: undefined,
          injection: undefined,
          production: undefined,
        },
        offtakeSelectedCount: undefined,
        injectionSelectedCount: undefined,
        productionSelectedCount: undefined,
      },
      () => {
        this.updateMetervalues(firstTime);
      }
    );
  }

  onSwitchSelectedEanOfftake(selectedSdps, allSdps, firstTime) {
    if (!config.MAINTENANCE_MV)
      this.setState(
        {
          sdps: {
            offtake: selectedSdps,
            allOfftake: allSdps,
            injection: this.state.sdps.injection,
            allInjection: this.state.sdps.allInjection,
            production: this.state.sdps.production,
            allProduction: this.state.sdps.allProduction,
          },
        },
        () => {
          if (firstTime) this.updateMetervalues();
        }
      );
    else if (firstTime) this.updateMetervalues();
  }

  onSwitchSelectedEanInjection(selectedSdps, allSdps, firstTime) {
    if (!config.MAINTENANCE_MV)
      this.setState(
        {
          sdps: {
            offtake: this.state.sdps.offtake,
            allOfftake: this.state.sdps.allOfftake,
            injection: selectedSdps,
            allInjection: allSdps,
            production: this.state.sdps.production,
            allProduction: this.state.sdps.allProduction,
          },
        },
        () => {
          if (firstTime) this.updateMetervalues();
        }
      );
    else if (firstTime) this.updateMetervalues();
  }

  onSwitchSelectedEanProduction(selectedSdps, allSdps, firstTime) {
    if (!config.MAINTENANCE_MV)
      this.setState(
        {
          sdps: {
            offtake: this.state.sdps.offtake,
            allOfftake: this.state.sdps.allOfftake,
            injection: this.state.sdps.injection,
            allInjection: this.state.sdps.allInjection,
            production: selectedSdps,
            allProduction: allSdps,
          },
        },
        () => {
          if (firstTime) this.updateMetervalues();
        }
      );
    else if (firstTime) this.updateMetervalues();
  }

  chartAggregationInfo(type) {
    let chartAggregationInfo = "";
    switch (this.state.aggregation) {
      case "H":
        chartAggregationInfo = this.strings.chartAggregationInfoHour;
        break;

      case "D":
        chartAggregationInfo = this.strings.chartAggregationInfoDay;
        break;

      case "M":
        chartAggregationInfo = this.strings.chartAggregationInfoMonth;
        break;

      default:
        break;
    }

    if (
      this.state.metervalues[type] &&
      this.state.metervalues[type].length &&
      this.state.aggregation
    )
      return (
        <div className="row">
          <div className="col-sm">
            <p className="ml-5 font-italic">
              *{" "}
              {this.state.aggregation === "H"
                ? this.strings.chartAggregationInfo1
                : this.strings.chartAggregationInfo2}{" "}
              <strong>{chartAggregationInfo}</strong>{" "}
              {this.strings.chartAggregationInfo3}
              <br />
              ** {this.strings.startDate}
            </p>
          </div>
        </div>
      );

    return <div />;
  }

  renderOfftake() {
    let inactiveEans =
      this.state.sdps.offtake &&
      this.state.sdps.offtake.filter((x) => x.contract_status === "ended"),
      flexibility =
        this.state.sdps.allOfftake &&
        this.state.sdps.allOfftake
          .filter((x) => x.hasFlexibility)
          .map((x) => x.ean);

    const sdpsWithFlexibility = this.state.selectedSdps.filter(sdp => sdp.hasFlexibility === 1);

    return (
      <div>
        <div className="row">
          <div className="col-sm py-2 mb-4">
            <Pickers
              isOfftake={false}
              metervalues={this.state.metervalues.offtake}
              aggregation={this.state.aggregation}
              sdp={this.state.sdps.offtake}
              allSdps={this.state.sdps.allOfftake}
              onChange={this.onSwitchSelectedEanOfftake}
              selectedCompany={this.props.selectedCompany}
              onChangePeriod={this.onChangePeriod}
              minDate={this.state.minDate}
              minContractDate={this.state.minContractDate}
              notStarted={this.state.notStarted}
              lang={this.props.lang}
              hideFlexibility={false} // to be removed?
              selectedSdps={this.state.selectedSdps}
              onSelectedSdpsChange={this.handleSelectedSdpsChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            {config.MAINTENANCE_MV ? (
              !this.state.belpex.length ? (
                <Loader>{this.strings.loading}</Loader>
              ) : (
                <ChartPlatformPrice
                  isInjection={false}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.offtake}
                  allSdps={this.state.sdps.allOfftake}
                  eanSelectedCount={this.state.offtakeSelectedCount}
                  belpex={this.state.belpex}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
              )
            ) : !this.state.sdps.offtake ||
              !this.state.metervalues.offtake ||
              this.state.offtakeSelectedCount === undefined ? (
              <div className="col-6 offset-3 text-center">
                <CtaButton
                  className="my-2 ml-2 btn btn-outline-primary"
                  link={"/services/onboarding"}
                >
                  <strong>
                    {this.strings.cta4} {this.strings.cta5}
                  </strong>
                </CtaButton>
              </div>
            ) : (
              <div>
                {inactiveEans.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-danger">
                        <i className="fas fa-exclamation-circle mr-2"></i>{" "}
                        {inactiveEans.length} {this.strings.endedContract}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.offtakeSelectedCount &&
                  this.state.metervalues.offtake &&
                  !this.state.metervalues.offtake.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>{" "}
                        {this.strings.noMeasured}
                      </div>
                    </div>
                  </div>
                ) : !this.state.offtakeSelectedCount &&
                  this.state.sdps.allOfftake &&
                  this.state.sdps.allOfftake.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>{" "}
                        {this.strings.noEanSelected}
                      </div>
                    </div>
                  </div>
                ) : null}
                {sdpsWithFlexibility.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>
                        <a href="https://inside.yuso.io">
                          {this.strings.hasFlexibility1}
                        </a>{" "}
                        {this.strings.hasFlexibility2} {flexibility}
                      </div>
                    </div>
                  </div>
                ) : null}
                <Stats
                  isInjection={false}
                  metervalues={this.state.metervalues.offtake}
                  aggregation={this.state.aggregation}
                  maxOfftakeMV={this.state.maxOfftakeMV}
                  sdp={this.state.sdps.offtake}
                  allSdps={this.state.sdps.allOfftake}
                  selectedCompany={this.props.selectedCompany}
                  from={this.state.from}
                  to={this.state.to}
                  lang={this.props.lang}
                  flexibility={flexibility}
                />
                <ChartPlatformPrice
                  isInjection={false}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.offtake}
                  allSdps={this.state.sdps.allOfftake}
                  eanSelectedCount={this.state.offtakeSelectedCount}
                  belpex={this.state.belpex}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
                <ChartMetervalues
                  isInjection={false}
                  metervalues={this.state.metervalues.offtake}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.offtake}
                  allSdps={this.state.sdps.allOfftake}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
                <ChartPL
                  isInjection={false}
                  metervalues={this.state.metervalues.offtake}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.offtake}
                  allSdps={this.state.sdps.allOfftake}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
                <PeakPower
                  metervalues={this.state.metervalues.offtake}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.offtake}
                  allSdps={this.state.sdps.allOfftake}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
              </div>
            )}
          </div>
        </div>
        {this.chartAggregationInfo("offtake")}
      </div>
    );
  }

  renderInjection() {
    let inactiveEans =
      this.state.sdps.injection &&
      this.state.sdps.injection.filter((x) => x.contract_status === "ended"),
      flexibility =
        this.state.sdps.allInjection &&
        this.state.sdps.allInjection
          .filter((x) => x.hasFlexibility)
          .map((x) => x.ean);

    const sdpsWithFlexibility = this.state.selectedSdps.filter(sdp => sdp.hasFlexibility === 1);

    return (
      <div>
        <div className="row">
          <div className="col-sm py-2 mb-4">
            <Pickers
              isInjection={true}
              metervalues={this.state.metervalues.injection}
              aggregation={this.state.aggregation}
              sdp={this.state.sdps.injection}
              allSdps={this.state.sdps.allInjection}
              onChange={this.onSwitchSelectedEanInjection}
              selectedCompany={this.props.selectedCompany}
              onChangePeriod={this.onChangePeriod}
              minDate={this.state.minDate}
              minContractDate={this.state.minContractDate}
              notStarted={this.state.notStarted}
              lang={this.props.lang}
              hideFlexibility={false} // to be removed?
              selectedSdps={this.state.selectedSdps}
              onSelectedSdpsChange={this.handleSelectedSdpsChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            {config.MAINTENANCE_MV ? (
              !this.state.belpex.length ? (
                <Loader>{this.strings.loading}</Loader>
              ) : (
                <ChartPlatformPrice
                  isInjection={true}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.injection}
                  allSdps={this.state.sdps.allInjection}
                  eanSelectedCount={this.state.injectionSelectedCount}
                  belpex={this.state.belpex}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
              )
            ) : !this.state.sdps.injection ||
              !this.state.metervalues.injection ||
              this.state.injectionSelectedCount === undefined ? (
              <Loader>{this.strings.loading}</Loader>
            ) : (
              <div>
                {inactiveEans.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-danger">
                        <i className="fas fa-exclamation-circle mr-2"></i>{" "}
                        {inactiveEans.length} {this.strings.endedContract}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.injectionSelectedCount &&
                  this.state.metervalues.injection &&
                  !this.state.metervalues.injection.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>{" "}
                        {this.strings.noMeasured}
                      </div>
                    </div>
                  </div>
                ) : !this.state.injectionSelectedCount &&
                  this.state.sdps.allInjection &&
                  this.state.sdps.allInjection.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>{" "}
                        {this.strings.noEanSelected}
                      </div>
                    </div>
                  </div>
                ) : null}
                {sdpsWithFlexibility.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>
                        <a href="https://inside.yuso.io">
                          {this.strings.hasFlexibility1}
                        </a>{" "}
                        {this.strings.hasFlexibility2} {flexibility}
                      </div>
                    </div>
                  </div>
                ) : null}
                <Stats
                  isInjection={true}
                  metervalues={this.state.metervalues.injection}
                  aggregation={this.state.aggregation}
                  maxInjectionMV={this.state.maxInjectionMV}
                  sdp={this.state.sdps.injection}
                  allSdps={this.state.sdps.allInjection}
                  selectedCompany={this.props.selectedCompany}
                  from={this.state.from}
                  to={this.state.to}
                  lang={this.props.lang}
                  flexibility={flexibility}
                />
                <ChartPlatformPrice
                  isInjection={true}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.injection}
                  allSdps={this.state.sdps.allInjection}
                  eanSelectedCount={this.state.injectionSelectedCount}
                  belpex={this.state.belpex}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
                <ChartMetervalues
                  isInjection={true}
                  metervalues={this.state.metervalues.injection}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.injection}
                  allSdps={this.state.sdps.allInjection}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
                <ChartPL
                  isInjection={true}
                  metervalues={this.state.metervalues.injection}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.injection}
                  allSdps={this.state.sdps.allInjection}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
                <PeakPower
                  metervalues={this.state.metervalues.injection}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.injection}
                  allSdps={this.state.sdps.allInjection}
                  minContractDate={this.state.minContractDate}
                  notStarted={this.state.notStarted}
                  lang={this.props.lang}
                />
              </div>
            )}
          </div>
        </div>
        {this.chartAggregationInfo("injection")}
      </div>
    );
  }

  renderProduction() {
    return (
      <div>
        <div className="row">
          <div className="col-sm py-2 mb-4">
            <Pickers
              isProduction={true}
              metervalues={this.state.metervalues.production}
              aggregation={this.state.aggregation}
              sdp={this.state.sdps.production}
              allSdps={this.state.sdps.allProduction}
              onChange={this.onSwitchSelectedEanProduction}
              selectedCompany={this.props.selectedCompany}
              onChangePeriod={this.onChangePeriod}
              minDate={this.state.minDate}
              minContractDate={this.state.minContractDate}
              notStarted={this.state.notStarted}
              lang={this.props.lang}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            {config.MAINTENANCE_MV ? (
              <div className="row" key="0">
                <div className="col-sm text-center">
                  <div className="d-inline-block alert alert-danger px-5 py-4">
                    <h5 className="m-0">
                      <i className="fas fa-exclamation-triangle mr-2"></i>{" "}
                      {this.strings.mvChangesInfo}
                    </h5>
                  </div>
                </div>
              </div>
            ) : !this.state.sdps.production ||
              !this.state.metervalues.production ||
              this.state.productionSelectedCount === undefined ? (
              <Loader>{this.strings.loading}</Loader>
            ) : (
              <div>
                {this.state.productionSelectedCount &&
                  this.state.metervalues.production &&
                  !this.state.metervalues.production.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>{" "}
                        {this.strings.noMeasured}
                      </div>
                    </div>
                  </div>
                ) : !this.state.productionSelectedCount &&
                  this.state.sdps.allProduction &&
                  this.state.sdps.allProduction.length ? (
                  <div className="row">
                    <div className="col-sm text-center">
                      <div className="d-inline-block alert alert-info">
                        <i className="fas fa-info-circle mr-2"></i>{" "}
                        {this.strings.noEanSelected}
                      </div>
                    </div>
                  </div>
                ) : null}
                <Stats
                  isProduction={true}
                  metervalues={this.state.metervalues.production}
                  aggregation={this.state.aggregation}
                  maxProductionMV={this.state.maxProductionMV}
                  sdp={this.state.sdps.production}
                  allSdps={this.state.sdps.allProduction}
                  selectedCompany={this.props.selectedCompany}
                  from={this.state.from}
                  to={this.state.to}
                  lang={this.props.lang}
                />
                <ChartMetervalues
                  isProduction={true}
                  metervalues={this.state.metervalues.production}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.production}
                  allSdps={this.state.sdps.allProduction}
                  lang={this.props.lang}
                />
                <PeakPower
                  metervalues={this.state.metervalues.production}
                  aggregation={this.state.aggregation}
                  sdp={this.state.sdps.production}
                  allSdps={this.state.sdps.allProduction}
                  lang={this.props.lang}
                />
              </div>
            )}
          </div>
        </div>
        {this.chartAggregationInfo("production")}
      </div>
    );
  }

  renderCertificates() {
    return (
      <Certificates
        selectedCompany={this.props.selectedCompany}
        lang={this.props.lang}
      />
    );
  }

  renderNetting() {
    return (
      <NettingChart
        selectedCompany={this.props.selectedCompany}
        lang={this.props.lang}
      />
    );
  }

  render() {
    if (!this.props.selectedCompany) {
      return <Loader inline="true">{this.strings.loading}</Loader>;
    }
    return (
      <div>
        <SubHeader name="Dashboards" type="Dashboards" />
        <div className="row">
          <div className="col-lg-7 px-2 px-xl-5 py-3">
            <div className="spacer"></div>
            <p className="mb-3">{this.strings.desc}</p>
          </div>
          <div className="col-lg-5 px-2 px-xl-5 py-3">
            <div className="spacer"></div>
            <Notifications lang={this.props.lang} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm px-2 px-xl-5 py-3">
            <Tabs
              titles={[
                { name: this.strings.supply, id: "supply" },
                { name: this.strings.injection, id: "injection" },
                { name: this.strings.production, id: "production" },
                { name: this.strings.certificates, id: "certificates" },
                { name: "Netting", id: "netting" },
              ]}
            >
              <div>{this.renderOfftake()}</div>
              <div>{this.renderInjection()}</div>
              <div>{this.renderProduction()}</div>
              <div>{this.renderCertificates()}</div>
              <div>{this.renderNetting()}</div>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboards;
